import { Box, Divider, List, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { type NavItem } from "../../types"
import NewsOneLogoLightTheme from "../../assets/logo/newsone-logo-light-theme.png"
import NewsOneLogoDarkTheme from "../../assets/logo/newsone-logo-dark-theme.png"

export const MobileDrawer = ({
  handleDrawerToggle,
  navItems,
}: {
  handleDrawerToggle: () => void
  navItems: NavItem[]
}): React.ReactElement => {
  const theme = useTheme()
  return (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "left" }}>
      <Box sx={{ my: 2, pl: 2 }}>
        <img
          src={theme.palette.mode === "light" ? NewsOneLogoLightTheme : NewsOneLogoDarkTheme}
          height={40}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item: NavItem) => (
          <Link
            to={item.to}
            style={{
              textDecoration: "none",
              color: theme.palette.secondary.main,
            }}
            key={item.to}
          >
            <ListItem key={item.to} disablePadding>
              <ListItemButton sx={{ textAlign: "left" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  )
}
