import { Drawer } from "@mui/material"
import React from "react"
import { MobileDrawer as MobileDrawerLocal } from "./drawer"
import { type NavItem } from "../../types"

const drawerWidth = 240

const MobileDrawer = ({
  mobileOpen,
  handleDrawerToggle,
  navItems,
}: {
  mobileOpen: boolean
  handleDrawerToggle: () => void
  navItems: NavItem[]
}): React.ReactElement => {
  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <nav>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <MobileDrawerLocal handleDrawerToggle={handleDrawerToggle} navItems={navItems} />
      </Drawer>
    </nav>
  )
}

export default MobileDrawer
