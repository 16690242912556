import React, { useMemo, useState } from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { darkTheme, lightTheme } from "./themes"

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

export const ToggleColorMode = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const [mode, setMode] = useState<"light" | "dark">("light")
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
      },
    }),
    [],
  )

  const theme = useMemo(() => createTheme(mode === "light" ? lightTheme : darkTheme), [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}
