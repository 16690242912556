import { type NavItem } from "../types"

export const homePage: NavItem = {
  name: "Home",
  to: "/",
}
export const sportsPage: NavItem = {
  name: "Sports",
  to: "/sports/",
}
export const techPage: NavItem = {
  name: "Tech",
  to: "/tech/",
}

export const entertainmentPage: NavItem = {
  name: "Entertainment",
  to: "/entertainment/",
}

export const politicsPage: NavItem = {
  name: "Politics",
  to: "/politics/",
}

export const regionalPage: NavItem = {
  name: "Regional",
  to: "/regional/",
}

export const navItems: NavItem[] = [
  homePage,
  sportsPage,
  techPage,
  politicsPage,
  regionalPage,
  entertainmentPage,
]
