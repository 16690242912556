import React from "react"
import { Box, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import SeoUpdater from "../../Components/SeoUpdater"
import { Facebook, Instagram, Reddit, Twitter } from "@mui/icons-material"

const populatePara = (text: string): React.ReactElement => {
  return (
    <Typography variant="body1" mb={2}>
      {text}
    </Typography>
  )
}

export default function ContactUs(): React.ReactElement {
  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai - Contact Us",
          commaSeparatedKeywords:
            "News, NewsOne, News One India, News One AI, NewsOne.ai Contact, Contact Us",
          titleTemplate: "%s",
          description: "NewsOne.ai - Connecting the dots.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "/contact-us",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={9} sm={12} xs={12}>
            <Typography variant="h4" component="h1" mb={2}>
              Contact Us
            </Typography>
            <Typography variant="subtitle1">
              We value your feedback and are here to assist you with any questions or concerns you
              may have. Please use the contact information below to get in touch with us.
            </Typography>
          </Grid>
          <Grid md={9} sm={12} xs={12}>
            <Typography variant="h5" component="h2" mb={2}>
              General Inquiries
            </Typography>
            {populatePara(
              "For general questions, feedback, or editorial inquiries, please contact us at:",
            )}
            <Typography variant="h6" component="h3">
              Email
            </Typography>
            <Typography variant="body1" mb={2}>
              <a href="mailto:contactus@newsone.ai">contactus@newsone.ai</a>
            </Typography>
            <Typography variant="h6" component="h3">
              Address
            </Typography>
            <Typography>Custofar Technologies Pvt. Ltd.</Typography>
            <Typography>Plot No. 89, Girija Nagar, Bhandewadi, Railway Station Road,</Typography>
            <Typography>Nagpur, Maharashtra, 440035.</Typography>
            <Typography mb={2}>India.</Typography>
            <Typography variant="h5" component="h2" mb={2}>
              Business Hours
            </Typography>
            {populatePara("Our team is available to assist you during the following hours:")}
            <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Monday to Friday:</span> 9:00 AM - 6:00 PM
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Saturday and Sunday:</span> Closed
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid md={3} sm={12} xs={12}></Grid>

          <Grid md={9} sm={12} xs={12}>
            <Typography variant="h5" component="h2" mb={2}>
              Follow Us on Social Media
            </Typography>
            <Box mb={2}>
              <IconButton
                href="https://www.facebook.com/newsoneai.official/"
                color="secondary"
                aria-label="facebook"
              >
                <Facebook />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/newsone.ai/"
                color="secondary"
                aria-label="instagram"
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="https://www.reddit.com/u/newsoneai"
                color="secondary"
                aria-label="reddit"
              >
                <Reddit />
              </IconButton>
              <IconButton
                href="https://twitter.com/newsoneai"
                color="secondary"
                aria-label="twitter"
              >
                <Twitter />
              </IconButton>
            </Box>
            {populatePara(
              "Thank you for visiting NewsOne.ai. We look forward to hearing from you!",
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
