import React, { useEffect } from "react"

const ScrollToTop = (): React.ReactElement => {
  useEffect(() => {
    /* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }, 0)
  }, [])
  return <></>
}

export default ScrollToTop
