import React, { useContext, useState } from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import { Outlet, Link } from "react-router-dom"
import MobileDrawer from "../Components/MobileDrawer"
import Footer from "../Components/Footer"
import { useTheme } from "@mui/material"
import { ColorModeContext } from "../contexts/theme"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import NewsOneLogoLightTheme from "../assets/logo/newsone-logo-light-theme.png"
import NewsOneLogoDarkTheme from "../assets/logo/newsone-logo-dark-theme.png"
import { navItems } from "../constants/pages"

export default function Layout(): React.ReactElement {
  const [mobileOpen, setMobileOpen] = useState(false)
  const theme = useTheme()
  const handleDrawerToggle = (): void => {
    setMobileOpen((prevState) => !prevState)
  }
  const { toggleColorMode } = useContext(ColorModeContext)
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
              <Link
                to="/"
                is="text"
                style={{ textDecoration: "none", color: theme.palette.secondary.dark }}
              >
                <img
                  src={
                    theme.palette.mode === "light" ? NewsOneLogoLightTheme : NewsOneLogoDarkTheme
                  }
                  height={40}
                  style={{ paddingTop: 10 }}
                />
              </Link>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button color="secondary" variant="text" key={item.to}>
                  <Link
                    to={item.to}
                    key={item.to}
                    is="text"
                    style={{ textDecoration: "none", color: theme.palette.secondary.main }}
                  >
                    {item.name}
                  </Link>
                </Button>
              ))}
              <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
                {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: { sm: "none" },
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                <img
                  style={{ verticalAlign: "middle" }}
                  src={
                    theme.palette.mode === "light" ? NewsOneLogoLightTheme : NewsOneLogoDarkTheme
                  }
                  height={35}
                  alt="newsone.ai logo"
                />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        <MobileDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          navItems={navItems}
        />
        <Outlet />
      </Box>
      <Footer />
    </>
  )
}
