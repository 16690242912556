import React from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material"
import ShareButtons from "../../Components/ShareButtons"
import { Link } from "react-router-dom"
import Grid from "@mui/material/Unstable_Grid2"
import whatIsCaa from "../../assets/articles/what-is-caa.json"
import mnsChiefMeetsRajThakrey from "../../assets/articles/mns-chief-raj-meets-amit-shah.json"
import SeoUpdater from "../../Components/SeoUpdater"

export default function Politics(): React.ReactElement {
  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai - Politics",
          commaSeparatedKeywords:
            "News, NewsOne, News One India, News One AI, Politics, Indian Politics",
          titleTemplate: "%s",
          description:
            "Delivering latest news on politics in India and around the world with our unbiased coverage.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "/politics/",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h1">
              Politics
            </Typography>
          </Grid>
          <Grid md={8} sm={12} xs={12}>
            <Card>
              <Link to={whatIsCaa.url} style={{ textDecoration: "none", color: "inherit" }}>
                <CardMedia
                  sx={{ height: 350 }}
                  image={whatIsCaa.image_url}
                  title="Matthew Perry - Who loved helping people."
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {whatIsCaa.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {whatIsCaa.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={whatIsCaa.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${whatIsCaa.url}`}
                        shareText={whatIsCaa.short_text}
                        shareTitle={whatIsCaa.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}></Grid>

          <Grid md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h2">
              Recent Stories
            </Typography>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            <Card>
              <Link
                to={mnsChiefMeetsRajThakrey.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={mnsChiefMeetsRajThakrey.image_url}
                  title={mnsChiefMeetsRajThakrey.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {mnsChiefMeetsRajThakrey.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {mnsChiefMeetsRajThakrey.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={mnsChiefMeetsRajThakrey.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${mnsChiefMeetsRajThakrey.url}`}
                        shareText={mnsChiefMeetsRajThakrey.short_text}
                        shareTitle={mnsChiefMeetsRajThakrey.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            {/* <Card>
              <Link
                to={ulfaPeaceDealWithGovernment.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={ulfaPeaceDealWithGovernment.image_url}
                  title={ulfaPeaceDealWithGovernment.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {ulfaPeaceDealWithGovernment.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {ulfaPeaceDealWithGovernment.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={ulfaPeaceDealWithGovernment.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${ulfaPeaceDealWithGovernment.url}`}
                        shareText={ulfaPeaceDealWithGovernment.short_text}
                        shareTitle={ulfaPeaceDealWithGovernment.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card> */}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
