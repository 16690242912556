import * as React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import Stack from "@mui/material/Stack"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import { type NavItem } from "../../types"
import { useTheme } from "@mui/material"

const CustomBreadcrumb = ({
  breadCrumb = [],
  articelTitle,
}: {
  breadCrumb: NavItem[]
  articelTitle: string
}): React.ReactElement => {
  const theme = useTheme()

  const breadcrumbs = [
    breadCrumb.map((item) => (
      <Link
        to={item.to}
        style={{
          textDecoration: "none",
          color: theme.palette.secondary.main,
          ...theme.typography.caption,
        }}
        key={item.to}
      >
        {item.name}
      </Link>
    )),
    <Typography key="title" variant="caption" color={theme.palette.text.primary}>
      {articelTitle}
    </Typography>,
  ]

  return (
    <Stack spacing={2} marginBottom={2}>
      <Breadcrumbs
        separator={
          <KeyboardDoubleArrowRightIcon
            sx={{
              ...theme.typography.caption,
              mt: "3px",
            }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  )
}

export default CustomBreadcrumb
