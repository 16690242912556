import { Facebook, Instagram, Reddit, Twitter } from "@mui/icons-material"
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import React from "react"
import { Link } from "react-router-dom"

const Footer = (): React.ReactElement => {
  const theme = useTheme()
  return (
    <Box
      component="footer"
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey.A100
            : theme.palette.getContrastText(theme.palette.grey.A100),
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} justifyContent="center" alignItems="center">
          <Typography textAlign="center" variant="h6">
            NewsOne.ai
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid justifyContent="center" alignItems="center" xs={12}>
          <Box textAlign="center">
            <IconButton
              href="https://www.facebook.com/newsoneai.official/"
              color="secondary"
              aria-label="facebook"
            >
              <Facebook color="secondary" />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/newsone.ai/"
              color="secondary"
              aria-label="instagram"
            >
              <Instagram />
            </IconButton>
            <IconButton
              href="https://www.reddit.com/u/newsoneai"
              color="secondary"
              aria-label="reddit"
            >
              <Reddit />
            </IconButton>
            <IconButton href="https://twitter.com/newsoneai" color="secondary" aria-label="twitter">
              <Twitter />
            </IconButton>
          </Box>
        </Grid>
        <Grid xs={12} justifyContent="center" alignItems="center">
          <Typography textAlign="center" variant="subtitle2">
            © {new Date().getFullYear()} Custofar Technologies Pvt. Ltd. All rights reserved.
          </Typography>
        </Grid>
        <Grid xs={12} justifyContent="center" alignItems="center">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Typography variant="subtitle2">
              <Link
                to="/about-us/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                About Us
              </Link>
            </Typography>
            <Typography variant="subtitle2">
              <Link
                to="/contact-us/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Contact Us
              </Link>
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              <Link
                to="/terms-and-conditions/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Terms and Conditions
              </Link>
            </Typography>
            <Typography variant="subtitle2">
              <Link
                to="/privacy-policy/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              display: { xs: "flex", sm: "none" },
            }}
          >
            <Typography variant="subtitle2">
              <Link
                to="/about-us/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                About Us
              </Link>
            </Typography>
            <Typography variant="subtitle2">
              <Link
                to="/contact-us/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Contact Us
              </Link>
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              <Link
                to="/terms-and-conditions/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Terms and Conditions
              </Link>
            </Typography>
            <Typography variant="subtitle2">
              <Link
                to="/privacy-policy/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
