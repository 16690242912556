import { type ThemeOptions } from "@mui/material/styles"
import { blueGrey, grey } from "@mui/material/colors"

const commonTypography = {
  typography: {
    // h1: {
    //   fontSize: 35,
    // },
  },
}

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: grey[50],
    },
    secondary: {
      main: grey[700],
    },
    text: {
      primary: grey[900],
      secondary: blueGrey[900],
    },
  },
  ...commonTypography,
}

export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    // primary: {
    //   main: "#fafbfb",
    // },
    secondary: {
      main: grey.A200,
    },
    // info: {
    //   main: "#424252",
    // },
    text: {
      primary: "#ffffff",
      secondary: "rgba(236,233,233,0.7)",
    },
    // success: {
    //   main: "#086010",
    // },
  },
  ...commonTypography,
}
