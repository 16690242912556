export const SHARE = {
  FACEBOOK: "facebook",
  REDDIT: "reddit",
  TWITTER: "twitter",
  LINK: "link",
}

export const ENVIRONMENT = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
}

export const URLS = {
  matthewPerryBlog: "/a-tribute-to-matthew-perry",
}

export const CONTENT_TYPES = {
  PARAGRAPH: "p",
  HEADING_ONE: "h1",
  HEADING_TWO: "h2",
  HEADING_THREE: "h3",
  ORDERED_LIST: "ol",
  UNORDERED_LIST: "ul",
  BLOCKQUOTE: "blockquote",
  NESTED_UNORDERED_LIST: "nested_ul",
}

export const noop = (): void => {}
