import { Link, createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from "../Pages/Home"
import Blog from "../Components/Blog"
import { Box, Toolbar, Typography } from "@mui/material"
import React from "react"
import Layout from "../Layout"
import matthewPerryTribute from "../assets/articles/matthew-perry.json"
import dataAnnotationIndustry from "../assets/articles/annotation-industry.json"
import indiaVsAustraliaFinal2023 from "../assets/articles/india-vs-austalia-final-2023.json"
import whatIsCaa from "../assets/articles/what-is-caa.json"
import reportingOnPsychedelics from "../assets/articles/reporting-on-psychedelics-research.json"
import extremeHeatHealthAffects from "../assets/articles/extreame-heat-health-research-roundup.json"
import mnsChiefMeetsRajThakrey from "../assets/articles/mns-chief-raj-meets-amit-shah.json"

import Sports from "../Pages/Sports"
import Tech from "../Pages/Tech"
import Entertainment from "../Pages/Entertainment"
import Politics from "../Pages/Politics"
import { entertainmentPage, homePage, politicsPage, sportsPage, techPage } from "../constants/pages"
import TermsAndConditions from "../Pages/TermsAndConditions"
import PrivacyPolicy from "../Pages/PrivacyPolicy"
import ContactUs from "../Pages/ContactUs"
import Regional from "../Pages/Regional"
import AboutUs from "../Pages/AboutUs"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/sports/",
        element: <Sports />,
      },
      {
        path: "/tech/",
        element: <Tech />,
      },
      {
        path: "/entertainment/",
        element: <Entertainment />,
      },
      {
        path: "/politics/",
        element: <Politics />,
      },
      {
        path: "/regional/",
        element: <Regional />,
      },
      {
        path: "/terms-and-conditions/",
        element: <TermsAndConditions />,
      },
      {
        path: "/contact-us/",
        element: <ContactUs />,
      },
      {
        path: "/about-us/",
        element: <AboutUs />,
      },
      {
        path: "/privacy-policy/",
        element: <PrivacyPolicy />,
      },
      {
        path: mnsChiefMeetsRajThakrey.url,
        element: (
          <Blog
            headerTags={{
              url: mnsChiefMeetsRajThakrey.url,
              title: mnsChiefMeetsRajThakrey.title,
              commaSeparatedKeywords: mnsChiefMeetsRajThakrey.keywords,
              titleTemplate: "%s | newsone.ai",
              description: mnsChiefMeetsRajThakrey.short_text,
              imageUrl: mnsChiefMeetsRajThakrey.image_url,
            }}
            article={{
              ...mnsChiefMeetsRajThakrey,
              shortText: mnsChiefMeetsRajThakrey.short_text,
              articleHeading: mnsChiefMeetsRajThakrey.article_heading,
            }}
            image={mnsChiefMeetsRajThakrey.image_url}
            breadCrumb={[homePage, politicsPage]}
          />
        ),
      },
      {
        path: "/regional/Maharashtra/Nagpur/a-womens-wing-shivsena-branch-opening-at-ward-25-nagpur/",
        async lazy() {
          const shivsenaWomenWingBranchAtWard25 = await import(
            "../assets/articles/regional-maharashtra-nagpur-shivsena-branch-opening.json"
          )
          return {
            element: (
              <Blog
                headerTags={{
                  url: shivsenaWomenWingBranchAtWard25.url,
                  title: shivsenaWomenWingBranchAtWard25.title,
                  commaSeparatedKeywords: shivsenaWomenWingBranchAtWard25.keywords,
                  titleTemplate: "%s | newsone.ai",
                  description: shivsenaWomenWingBranchAtWard25.short_text,
                  imageUrl: shivsenaWomenWingBranchAtWard25.image_url,
                }}
                article={{
                  ...shivsenaWomenWingBranchAtWard25,
                  shortText: shivsenaWomenWingBranchAtWard25.short_text,
                  articleHeading: shivsenaWomenWingBranchAtWard25.article_heading,
                }}
                image={shivsenaWomenWingBranchAtWard25.image_url}
                breadCrumb={[homePage, politicsPage]}
              />
            ),
          }
        },
      },
      {
        path: whatIsCaa.url,
        element: (
          <Blog
            headerTags={{
              url: whatIsCaa.url,
              title: whatIsCaa.title,
              commaSeparatedKeywords: whatIsCaa.keywords,
              titleTemplate: "%s | newsone.ai",
              description: whatIsCaa.short_text,
              imageUrl: whatIsCaa.image_url,
            }}
            article={{
              ...whatIsCaa,
              shortText: whatIsCaa.short_text,
              articleHeading: whatIsCaa.article_heading,
            }}
            image={whatIsCaa.image_url}
            breadCrumb={[homePage]}
          />
        ),
      },
      {
        path: extremeHeatHealthAffects.url,
        element: (
          <Blog
            headerTags={{
              url: extremeHeatHealthAffects.url,
              title: extremeHeatHealthAffects.title,
              commaSeparatedKeywords: extremeHeatHealthAffects.keywords,
              titleTemplate: "%s | newsone.ai",
              description: extremeHeatHealthAffects.short_text,
              imageUrl: extremeHeatHealthAffects.image_url,
            }}
            article={{
              ...extremeHeatHealthAffects,
              shortText: extremeHeatHealthAffects.short_text,
              articleHeading: extremeHeatHealthAffects.article_heading,
            }}
            image={extremeHeatHealthAffects.image_url}
            breadCrumb={[homePage]}
          />
        ),
      },
      {
        path: reportingOnPsychedelics.url,
        element: (
          <Blog
            headerTags={{
              url: reportingOnPsychedelics.url,
              title: reportingOnPsychedelics.title,
              commaSeparatedKeywords: reportingOnPsychedelics.keywords,
              titleTemplate: "%s | newsone.ai",
              description: reportingOnPsychedelics.short_text,
              imageUrl: reportingOnPsychedelics.image_url,
            }}
            article={{
              ...reportingOnPsychedelics,
              shortText: reportingOnPsychedelics.short_text,
              articleHeading: reportingOnPsychedelics.article_heading,
            }}
            image={reportingOnPsychedelics.image_url}
            breadCrumb={[homePage]}
          />
        ),
      },
      {
        path: indiaVsAustraliaFinal2023.url,
        element: (
          <Blog
            headerTags={{
              url: indiaVsAustraliaFinal2023.url,
              title: indiaVsAustraliaFinal2023.title,
              commaSeparatedKeywords: indiaVsAustraliaFinal2023.keywords,
              titleTemplate: "%s | newsone.ai",
              description: indiaVsAustraliaFinal2023.short_text,
              imageUrl: indiaVsAustraliaFinal2023.image_url,
            }}
            article={{
              ...indiaVsAustraliaFinal2023,
              shortText: indiaVsAustraliaFinal2023.short_text,
              articleHeading: indiaVsAustraliaFinal2023.article_heading,
            }}
            image={indiaVsAustraliaFinal2023.image_url}
            breadCrumb={[homePage, sportsPage]}
          />
        ),
      },
      {
        path: matthewPerryTribute.url,
        element: (
          <Blog
            headerTags={{
              url: matthewPerryTribute.url,
              title: matthewPerryTribute.title,
              commaSeparatedKeywords: matthewPerryTribute.keywords,
              titleTemplate: "%s | newsone.ai",
              description: matthewPerryTribute.short_text,
              imageUrl: matthewPerryTribute.image_url,
            }}
            article={{
              ...matthewPerryTribute,
              shortText: matthewPerryTribute.short_text,
              articleHeading: matthewPerryTribute.article_heading,
            }}
            image={matthewPerryTribute.image_url}
            breadCrumb={[homePage, entertainmentPage]}
          />
        ),
      },
      {
        path: dataAnnotationIndustry.url,
        element: (
          <Blog
            headerTags={{
              url: dataAnnotationIndustry.url,
              title: dataAnnotationIndustry.title,
              commaSeparatedKeywords: dataAnnotationIndustry.keywords,
              titleTemplate: "%s | newsone.ai",
              description: dataAnnotationIndustry.short_text,
              imageUrl: dataAnnotationIndustry.image_url,
            }}
            article={{
              ...dataAnnotationIndustry,
              shortText: dataAnnotationIndustry.short_text,
              articleHeading: dataAnnotationIndustry.article_heading,
            }}
            image={dataAnnotationIndustry.image_url}
            breadCrumb={[homePage, techPage]}
          />
        ),
      },
      // {
      //   path: "about",
      //   // Single route in lazy file
      //   lazy: () => import("../Pages/About"),
      // },
      // {
      //   path: "dashboard",
      //   async lazy() {
      //     // Multiple routes in lazy file
      //     let { DashboardLayout } = await import("../Pages/Dashboard");
      //     return { Component: DashboardLayout };
      //   },
      //   children: [
      //     {
      //       index: true,
      //       async lazy() {
      //         let { DashboardIndex } = await import("../Pages/Dashboard");
      //         return { Component: DashboardIndex };
      //       },
      //     },
      //     {
      //       path: "messages",
      //       async lazy() {
      //         let { dashboardMessagesLoader, DashboardMessages } = await import(
      //           "../Pages/Dashboard"
      //         );
      //         return {
      //           loader: dashboardMessagesLoader,
      //           Component: DashboardMessages,
      //         };
      //       },
      //     },
      //   ],
      // },
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
])

export default function RootRouter(): React.ReactElement {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
}

function NoMatch(): React.ReactElement {
  return (
    <Box component="main" sx={{ p: 3 }}>
      <Toolbar />
      <Typography variant="h2">
        I think you are lost but you can alway go <Link to="/">Home</Link>!
      </Typography>
    </Box>
  )
}
