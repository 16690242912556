import React from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material"
import ShareButtons from "../../Components/ShareButtons"
import { Link } from "react-router-dom"
import Grid from "@mui/material/Unstable_Grid2"
import tributToMatthewPerry from "../../assets/articles/matthew-perry.json"
import SeoUpdater from "../../Components/SeoUpdater"

export default function Entertainment(): React.ReactElement {
  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai - Entertainment",
          commaSeparatedKeywords: "News, NewsOne, News One India, News One AI, Entertainment",
          titleTemplate: "%s",
          description: "NewsOne.ai - Connecting the dots.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "/entertainment/",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h1">
              Entertainment
            </Typography>
          </Grid>
          <Grid md={8} sm={12} xs={12}>
            <Card>
              <Link
                to={tributToMatthewPerry.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={tributToMatthewPerry.image_url}
                  title="Matthew Perry - Who loved helping people."
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {tributToMatthewPerry.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {tributToMatthewPerry.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={tributToMatthewPerry.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${tributToMatthewPerry.url}`}
                        shareText={tributToMatthewPerry.short_text}
                        shareTitle={tributToMatthewPerry.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </>
  )
}
