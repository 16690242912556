import React from "react"
import { Box, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import SeoUpdater from "../../Components/SeoUpdater"
import { Facebook, Instagram, Reddit, Twitter } from "@mui/icons-material"

const populatePara = (text: string): React.ReactElement => {
  return (
    <Typography variant="body1" mb={2}>
      {text}
    </Typography>
  )
}

export default function AboutUs(): React.ReactElement {
  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai - About Us",
          commaSeparatedKeywords:
            "News, NewsOne, News One India, News One AI, NewsOne.ai About, About Us",
          titleTemplate: "%s",
          description: "NewsOne.ai - Connecting the dots.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "/about-us",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={9} sm={12} xs={12}>
            <Typography variant="h4" component="h1" mb={2}>
              About Us
            </Typography>
          </Grid>
          <Grid md={9} sm={12} xs={12}>
            <Typography variant="h5" component="h2" mb={2}>
              Welcome to NewsOne.ai
            </Typography>
            {populatePara(
              "At NewsOne.ai, we are revolutionizing the way you consume news. Our mission is to provide you with the most accurate, timely, and relevant news stories from around the globe, powered by cutting-edge artificial intelligence.",
            )}
            <Typography variant="h5" component="h2" mb={2}>
              Our Story
            </Typography>
            {populatePara(
              "Founded in 2023, NewsOne.ai was born out of a passion for technology and a commitment to delivering high-quality journalism. Our team of experienced journalists, data scientists, and AI experts work tirelessly to ensure that you receive news that matters, when it matters.",
            )}
            <Typography variant="h5" component="h2" mb={2}>
              What We Do
            </Typography>
            <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Reliable Sources: </span> We prioritize accuracy
                      and integrity, partnering with reputable news organizations to ensure the
                      information you receive is trustworthy and credible.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Innovative Features:</span> From real-time updates
                      to in-depth analysis, features are being developed to enhance your news
                      consumption experience. We are developing tools that allow you to delve deeper
                      into stories, offering a comprehensive view of current events.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography variant="h5" component="h2" mb={2}>
              Our Vision
            </Typography>
            {populatePara(
              "We envision a world where everyone has access to reliable and unbiased news with traceability of the source of news. By leveraging AI, we aim to bridge the gap between information and accessibility and traceability of news, ensuring that you stay informed, empowered and routable to the source of news.",
            )}
            <Typography variant="h5" component="h2" mb={2}>
              Our Team
            </Typography>
            {populatePara(
              "Behind NewsOne.ai is a diverse team of journalists, technologists, and designers who share a passion for news and innovation. Our collective expertise drives us to continually improve our platform, ensuring it meets the evolving needs of our readers.",
            )}
            <Typography variant="h5" component="h2" mb={2}>
              Join Us
            </Typography>
            {populatePara(
              "We're always on the lookout for talented individuals who share our vision. If you're passionate about technology and journalism, we'd love to hear from you! Reach out to us at contactus@newsone.ai for current openings.",
            )}
            <Typography variant="h5" component="h2" mb={2}>
              Contact Us
            </Typography>
            {populatePara(
              "Got questions or feedback? We'd love to hear from you! Reach out to us at contactus@newsone.ai. Your input is invaluable to us, and we're here to help.",
            )}
          </Grid>
          <Grid md={3} sm={12} xs={12}></Grid>

          <Grid md={9} sm={12} xs={12}>
            <Typography variant="h5" component="h2" mb={2}>
              Follow Us on Social Media
            </Typography>
            <Box mb={2}>
              <IconButton
                href="https://www.facebook.com/newsoneai.official/"
                color="secondary"
                aria-label="facebook"
              >
                <Facebook />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/newsone.ai/"
                color="secondary"
                aria-label="instagram"
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="https://www.reddit.com/u/newsoneai"
                color="secondary"
                aria-label="reddit"
              >
                <Reddit />
              </IconButton>
              <IconButton
                href="https://twitter.com/newsoneai"
                color="secondary"
                aria-label="twitter"
              >
                <Twitter />
              </IconButton>
            </Box>
            {populatePara(
              "Thank you for visiting NewsOne.ai. We look forward to hearing from you!",
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
