import React from "react"
import ReactDOM from "react-dom/client"
import ReactGA from "react-ga4"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import RootRouter from "./Router"
import { ENVIRONMENT } from "./constants"
import { ToggleColorMode } from "./contexts/theme"
import { AppContextWrapper } from "./contexts/app"

if (process.env.NODE_ENV === ENVIRONMENT.PRODUCTION) {
  ReactGA.initialize(process.env?.REACT_APP_GA_ID ?? "")
}

const rootElement = document.getElementById("root") as HTMLElement

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <React.StrictMode>
      <AppContextWrapper>
        <ToggleColorMode>
          <RootRouter />
        </ToggleColorMode>
      </AppContextWrapper>
    </React.StrictMode>,
  )
} else {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <ToggleColorMode>
        <RootRouter />
      </ToggleColorMode>
    </React.StrictMode>,
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
