import React from "react"
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Link,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import SeoUpdater from "../../Components/SeoUpdater"
import { CONTENT_TYPES } from "../../constants"
import { type ListContentType } from "../../types"
import "./styles.css"
import { poppulateNestedList, poppulateParagraph } from "../../Components/Blog"

const poppulateTableOfContentsList = (content: ListContentType): React.ReactElement => {
  return (
    <List sx={{ listStyle: "decimal", pl: 4, pt: 0, pb: 0.5 }}>
      {content.texts.map((item, index) => {
        return (
          <ListItem key={index} sx={{ display: "list-item", p: 0 }}>
            <ListItemText
              primary={
                <Link href={`#${item.replaceAll(" ", "-")}`} color="secondary" underline="hover">
                  {item}
                </Link>
              }
            />
          </ListItem>
        )
      })}
    </List>
  )
}

const populateTermHeading = (heading: string, id: string): React.ReactElement => {
  return (
    <Typography variant="h6" component="h3" mt={4} mb={2} id={id}>
      {heading}
    </Typography>
  )
}

const populatePara = (text: string): React.ReactElement => {
  return (
    <Typography variant="body1" mb={2}>
      {text}
    </Typography>
  )
}

export default function PrivacyPolicy(): React.ReactElement {
  const theme = useTheme()

  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai - Privacy Policy",
          commaSeparatedKeywords:
            "News, NewsOne, News One India, News One AI, NewsOne.ai Privacy Policy, Privacy Policy",
          titleTemplate: "%s",
          description: "NewsOne.ai - Connecting the dots.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "/privacy-policy",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={12} sm={12} xs={12}>
            <Typography variant="h4" component="h1" mb={2}>
              PRIVACY POLICY
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: "bold",
              }}
            >
              Last updated May 26, 2024
            </Typography>
          </Grid>
          <Grid md={9} sm={12} xs={12}>
            <Typography variant="body1" mb={2}>
              This privacy notice for Custofar Technologies Pvt. Ltd. (doing business as newsone.ai)
              ("
              <span className="bold-text">we</span>," "<span className="bold-text">us</span>," or "
              <span className="bold-text">our</span>"), describes how and why we might collect,
              store, use, and/or share ( "<span className="bold-text">process</span>") your
              information when you use our services ("<span className="bold-text">Services</span>"),
              such as when you:
            </Typography>
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "Visit our website at https://www.newsone.ai, or any website of ours that links to this privacy notice",
                  lists: [],
                  links: [
                    {
                      text: "https://www.newsone.ai",
                      url: "https://www.newsone.ai",
                    },
                  ],
                },
                {
                  text: "Engage with us in other related ways, including any sales, marketing, or events",
                  lists: [],
                },
              ],
            })}
            <Typography variant="body1" mb={2}>
              <span className="bold-text">Questions or concerns?</span> Reading this privacy notice
              will help you understand your privacy rights and choices. If you do not agree with our
              policies and practices, please do not use our Services. If you still have any
              questions or concerns, please contact us at{" "}
              <a href="mailto:contactus@newsone.ai">contactus@newsone.ai</a>.
            </Typography>
            <Typography mt={4} mb={2} variant="h6" component="h3">
              SUMMARY OF KEY POINTS
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" fontStyle="italic" mb={2}>
              This summary provides key points from our privacy notice, but you can find out more
              details about any of these topics by clicking the link following each key point or by
              using our <a href="#table-of-contents">table of contents</a> below to find the section
              you are looking for.
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text" color={theme.palette.text.secondary}>
                What personal information do we process?
              </span>{" "}
              When you visit, use, or navigate our Services, we may process personal information
              depending on how you interact with us and the Services, the choices you make, and the
              products and features you use. Learn more about{" "}
              <a href="#personal-information-you-disclose-to-us">
                personal information you disclose to us
              </a>
              .
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text" color={theme.palette.text.primary}>
                Do we process any sensitive personal information?
              </span>{" "}
              We do not process sensitive personal information.
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text">Do we collect any information from third parties?</span>{" "}
              We do not collect any information from third parties.
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text" color={theme.palette.text.secondary}>
                How do we process your information?
              </span>{" "}
              We process your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to comply with law. We
              may also process your information for other purposes with your consent. We process
              your information only when we have a valid legal reason to do so. Learn more about{" "}
              <a href="#HOW-DO-WE-PROCESS-YOUR-INFORMATION?">how we process your information</a>.
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text">
                In what situations and with which parties do we share personal information?
              </span>{" "}
              We may share information in specific situations and with specific third parties. Learn
              more about{" "}
              <a href="#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?">
                when and with whom we share your personal information
              </a>
              .
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text">How do we keep your information safe?</span> We have
              organizational and technical processes and procedures in place to protect your
              personal information. However, no electronic transmission over the internet or
              information storage technology can be guaranteed to be 100% secure, so we cannot
              promise or guarantee that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security and improperly collect, access, steal, or
              modify your information. Learn more about{" "}
              <a href="#HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE?">how we keep your information safe</a>
              .
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text">What are your rights?</span> Depending on where you are
              located geographically, the applicable privacy law may mean you have certain rights
              regarding your personal information. Learn more about{" "}
              <a href="#WHAT-ARE-YOUR-PRIVACY-RIGHTS?">your privacy rights</a>.
            </Typography>
            <Typography variant="body1" mb={2}>
              <span className="bold-text">How do you exercise your rights?</span> The easiest way to
              exercise your rights is by contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </Typography>
            <Typography variant="body1" mb={2}>
              Want to learn more about what we do with any information we collect?{" "}
              <a href="#table-of-contents">Review the privacy notice in full</a>.
            </Typography>
            <Typography id="table-of-contents" mt={4} variant="h6" component="h3">
              TABLE OF CONTENTS
            </Typography>
            {poppulateTableOfContentsList({
              texts: [
                "WHAT INFORMATION DO WE COLLECT?",
                "HOW DO WE PROCESS YOUR INFORMATION?",
                "WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?",
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
                "WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?",
                "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
                "HOW LONG DO WE KEEP YOUR INFORMATION?",
                "HOW DO WE KEEP YOUR INFORMATION SAFE?",
                "DO WE COLLECT INFORMATION FROM MINORS?",
                "WHAT ARE YOUR PRIVACY RIGHTS?",
                "CONTROLS FOR DO-NOT-TRACK FEATURES",
                "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
                "DO WE MAKE UPDATES TO THIS NOTICE?",
                "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
                "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
              ],
              type: CONTENT_TYPES.ORDERED_LIST,
            })}
            {populateTermHeading(
              "1. WHAT INFORMATION DO WE COLLECT?",
              "WHAT-INFORMATION-DO-WE-COLLECT?",
            )}
            <Typography
              id="personal-information-you-disclose-to-us"
              variant="subtitle1"
              fontWeight="bold"
              mb={1.5}
            >
              Personal information you disclose to us
            </Typography>
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We collect personal information that you
              provide to us.
            </Typography>
            {populatePara(
              "We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.",
            )}
            <Typography variant="body1" mb={2}>
              <span className="bold-text">Personal Information Provided by You</span>. The personal
              information that we collect depends on the context of your interactions with us and
              the Services, the choices you make, and the products and features you use. The
              personal information we collect may include the following:
            </Typography>
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "email addresses",
                  lists: [],
                },
                {
                  text: "names",
                  lists: [],
                },
              ],
            })}
            <Typography variant="body1" mb={2}>
              {" "}
              <span className="bold-text">Sensitive Information</span>. We do not process sensitive
              information.
            </Typography>
            {populatePara(
              "All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.",
            )}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              Information automatically collected
            </Typography>
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> Some information — such as your Internet
              Protocol (IP) address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </Typography>
            {populatePara(
              "We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.",
            )}
            {populatePara(
              "Like many businesses, we also collect information through cookies and similar technologies.",
            )}
            {populatePara("The information we collect includes:")}
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called \"crash dumps\"), and hardware settings).",
                  lists: [],
                },
                {
                  text: "Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.",
                  lists: [],
                },
                {
                  text: "Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.",
                  lists: [],
                },
              ],
            })}
            {populateTermHeading(
              "2. HOW DO WE PROCESS YOUR INFORMATION?",
              "HOW-DO-WE-PROCESS-YOUR-INFORMATION?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We process your information to provide,
              improve, and administer our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your information for other
              purposes with your consent.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              We process your personal information for a variety of reasons, depending on how you
              interact with our Services, including:
            </Typography>
            <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">To request feedback.</span> We may process your
                      information when necessary to request feedback and to contact you about your
                      use of our Services.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">
                        To send you marketing and promotional communications.
                      </span>{" "}
                      We may process the personal information you send to us for our marketing
                      purposes, if this is in accordance with your marketing preferences. You can
                      opt out of our marketing emails at any time. For more information, see "
                      <a href="#WHAT-ARE-YOUR-PRIVACY-RIGHTS?">WHAT ARE YOUR PRIVACY RIGHTS?</a> "
                      below.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">
                        To save or protect an individual's vital interest.
                      </span>{" "}
                      We may process your information when necessary to save or protect an
                      individual’s vital interest, such as to prevent harm.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            {populateTermHeading(
              "3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?",
              "WHAT-LEGAL-BASES-DO-WE-RELY-ON-TO-PROCESS-YOUR-INFORMATION?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We only process your personal information
              when we believe it is necessary and we have a valid legal reason (i.e. , legal basis)
              to do so under applicable law, like with your consent, to comply with laws, to provide
              you with services to enter into or fulfill our contractual obligations, to protect
              your rights, or to fulfill our legitimate business interests.
            </Typography>
            <Typography
              variant="body1"
              mb={2}
              fontStyle="italic"
              color="secondary"
              fontWeight="bold"
              sx={{
                textDecoration: "underline",
              }}
            >
              If you are located in the EU or UK, this section applies to you.
            </Typography>
            {populatePara(
              "The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:",
            )}
            <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Consent.</span> We may process your information if
                      you have given us permission (i.e. , consent) to use your personal information
                      for a specific purpose. You can withdraw your consent at any time. Learn more
                      about <a href="#withdrawing-your-consent">withdrawing your consent</a>.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Legitimate Interests.</span> We may process your
                      information when we believe it is reasonably necessary to achieve our
                      legitimate business interests and those interests do not outweigh your
                      interests and fundamental rights and freedoms. For example, we may process
                      your personal information for some of the purposes described in order to:
                    </Typography>
                  }
                />
                <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
                  <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                    <ListItemText
                      primary={
                        <Typography variant="body1" mb={2}>
                          Send users information about special offers and discounts on our products
                          and services
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                    <ListItemText
                      primary={
                        <Typography variant="body1" mb={2}>
                          Understand how our users use our products and services so we can improve
                          user experience
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Legal Obligations.</span> We may process your
                      information where we believe it is necessary for compliance with our legal
                      obligations, such as to cooperate with a law enforcement body or regulatory
                      agency, exercise or defend our legal rights, or disclose your information as
                      evidence in litigation in which we are involved.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Vital Interests.</span> We may process your
                      information where we believe it is necessary to protect your vital interests
                      or the vital interests of a third party, such as situations involving
                      potential threats to the safety of any person.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography
              variant="body1"
              mb={2}
              fontStyle="italic"
              color="secondary"
              fontWeight="bold"
              sx={{
                textDecoration: "underline",
              }}
            >
              If you are located in Canada, this section applies to you.
            </Typography>
            <Typography variant="body1" mb={2}>
              We may process your information if you have given us specific permission (i.e. ,
              express consent) to use your personal information for a specific purpose, or in
              situations where your permission can be inferred (i.e. , implied consent). You can{" "}
              <a href="#withdrawing-your-consent">withdraw your consent</a> at any time.
            </Typography>
            {populatePara(
              "In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:",
            )}
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  lists: [],
                  text: "If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way",
                },
                {
                  lists: [],
                  text: "For investigations and fraud detection and prevention",
                },
                {
                  lists: [],
                  text: "For business transactions provided certain conditions are met",
                },
                {
                  lists: [],
                  text: "If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim",
                },
                {
                  lists: [],
                  text: "For identifying injured, ill, or deceased persons and communicating with next of kin",
                },
                {
                  lists: [],
                  text: "If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse",
                },
                {
                  lists: [],
                  text: "If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province",
                },
                {
                  lists: [],
                  text: "If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records",
                },
                {
                  lists: [],
                  text: "If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced",
                },
                {
                  lists: [],
                  text: "If the collection is solely for journalistic, artistic, or literary purposes",
                },
                {
                  lists: [],
                  text: "If the information is publicly available and is specified by the regulations",
                },
              ],
            })}

            {populateTermHeading(
              "4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
              "WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We may share information in specific
              situations described in this section and/or with the following third parties.
            </Typography>
            {populatePara(
              "We may need to share your personal information in the following situations:",
            )}
            <List sx={{ listStyle: "unset", pl: 4, pt: 0, pb: 0.5 }}>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">Business Transfers.</span> We may share or
                      transfer your information in connection with, or during negotiations of, any
                      merger, sale of company assets, financing, or acquisition of all or a portion
                      of our business to another company.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0, whiteSpace: "pre-line" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" mb={2}>
                      <span className="bold-text">When we use Google Maps Platform APIs.</span>We
                      may share your information with certain Google Maps Platform APIs (e.g. ,
                      Google Maps API, Places API).
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            {populateTermHeading(
              "5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?",
              "WHAT-IS-OUR-STANCE-ON-THIRD-PARTY-WEBSITES?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span>We are not responsible for the safety of
              any information that you share with third parties that we may link to or who advertise
              on our Services, but are not affiliated with, our Services.
            </Typography>
            {populatePara(
              "The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.",
            )}

            {populateTermHeading(
              "6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
              "DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We may use cookies and other tracking
              technologies to collect and store your information.
            </Typography>
            {populatePara(
              "We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services , prevent crashes, fix bugs, save your preferences, and assist with basic site functions.",
            )}
            {populatePara(
              "We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.",
            )}
            <Typography variant="body1" mb={2}>
              To the extent these online tracking technologies are deemed to be a "sale"/"sharing"
              (which includes targeted advertising, as defined under the applicable laws) under
              applicable US state laws, you can opt out of these online tracking technologies by
              submitting a request as described below under section "{" "}
              <a href="#DO-UNITED-STATES-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS?">
                DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>{" "}
              "
            </Typography>
            {populatePara(
              "Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.",
            )}
            <Typography variant="subtitle1" mb={2} fontWeight="bold">
              Google Analytics
            </Typography>
            {poppulateParagraph({
              type: CONTENT_TYPES.PARAGRAPH,
              text: "We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features that we may use include: Remarketing with Google Analytics , Google Display Network Impressions Reporting and Google Analytics Demographics and Interests Reporting . To opt out of being tracked by Google Analytics across the Services, visit https://tools.google.com/dlpage/gaoptout. You can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means include http://optout.networkadvertising.org/ and http://www.networkadvertising.org/mobile-choice. For more information on the privacy practices of Google, please visit the Google Privacy & Terms page.",
              noLineBreakAtEnd: true,
              links: [
                {
                  text: "https://tools.google.com/dlpage/gaoptout",
                  url: "https://tools.google.com/dlpage/gaoptout",
                },
                {
                  text: "Ads Settings",
                  url: "https://adssettings.google.com/",
                },
                {
                  text: "http://optout.networkadvertising.org/",
                  url: "http://optout.networkadvertising.org/",
                },
                {
                  url: "http://www.networkadvertising.org/mobile-choice",
                  text: "http://www.networkadvertising.org/mobile-choice",
                },
                {
                  text: "Google Privacy & Terms page",
                  url: "https://policies.google.com/privacy",
                },
              ],
            })}

            {populateTermHeading(
              "7. HOW LONG DO WE KEEP YOUR INFORMATION?",
              "HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy notice unless otherwise
              required by law.
            </Typography>
            {populatePara(
              "We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).",
            )}
            {populatePara(
              "When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.",
            )}

            {populateTermHeading(
              "8. HOW DO WE KEEP YOUR INFORMATION SAFE?",
              "HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We aim to protect your personal
              information through a system of organizational and technical security measures.
            </Typography>
            {populatePara(
              "We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.",
            )}

            {populateTermHeading(
              "9. DO WE COLLECT INFORMATION FROM MINORS?",
              "DO-WE-COLLECT-INFORMATION-FROM-MINORS?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> We do not knowingly collect data from or
              market to children under 18 years of age.
            </Typography>
            {populatePara(
              "We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at contactus@newsone.ai .",
            )}

            {populateTermHeading(
              "10. WHAT ARE YOUR PRIVACY RIGHTS?",
              "WHAT-ARE-YOUR-PRIVACY-RIGHTS?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> Depending on your state of residence in
              the US or in some regions, such as the European Economic Area (EEA), United Kingdom
              (UK), Switzerland, and Canada , you have rights that allow you greater access to and
              control over your personal information. You may review, change, or terminate your
              account at any time, depending on your country, province, or state of residence.
            </Typography>
            <Typography variant="body1" mb={2}>
              In some regions (like the EEA, UK, Switzerland, and Canada ), you have certain rights
              under applicable data protection laws. These may include the right (i) to request
              access and obtain a copy of your personal information, (ii) to request rectification
              or erasure; (iii) to restrict the processing of your personal information; (iv) if
              applicable, to data portability; and (v) not to be subject to automated
              decision-making. In certain circumstances, you may also have the right to object to
              the processing of your personal information. You can make such a request by contacting
              us by using the contact details provided in the section "
              <a href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              " below.
            </Typography>
            {populatePara(
              "We will consider and act upon any request in accordance with applicable data protection laws.",
            )}
            {poppulateParagraph({
              type: CONTENT_TYPES.PARAGRAPH,
              text: "If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.",
              noLineBreakAtEnd: false,
              links: [
                {
                  text: "Member State data protection authority",
                  url: "https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm",
                },
                {
                  text: "UK data protection authority",
                  url: "https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/",
                },
              ],
            })}
            {poppulateParagraph({
              type: CONTENT_TYPES.PARAGRAPH,
              text: "If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.",
              noLineBreakAtEnd: false,
              links: [
                {
                  text: "Federal Data Protection and Information Commissioner",
                  url: "https://www.edoeb.admin.ch/edoeb/en/home.html",
                },
              ],
            })}
            <Typography variant="body1" mb={2}>
              <span
                className="bold-text"
                style={{
                  textDecoration: "underline",
                }}
                id="withdrawing-your-consent"
              >
                Withdrawing your consent:
              </span>{" "}
              If we are relying on your consent to process your personal information, which may be
              express and/or implied consent depending on the applicable law, you have the right to
              withdraw your consent at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section "
              <a href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              " below .
            </Typography>
            {populatePara(
              "However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.",
            )}
            <Typography variant="body1" mb={2}>
              <span
                className="bold-text"
                style={{
                  textDecoration: "underline",
                }}
              >
                Opting out of marketing and promotional communications:
              </span>{" "}
              You can unsubscribe from our marketing and promotional communications at any time by
              clicking on the unsubscribe link in the emails that we send, or by contacting us using
              the details provided in the section "{" "}
              <a href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              " below. You will then be removed from the marketing lists. However, we may still
              communicate with you — for example, to send you service-related messages that are
              necessary for the administration and use of your account, to respond to service
              requests, or for other non-marketing purposes.
            </Typography>
            <Typography variant="body1" mb={2}>
              <span
                className="bold-text"
                style={{
                  textDecoration: "underline",
                }}
                id="withdrawing-your-consent"
              >
                Cookies and similar technologies:
              </span>{" "}
              Most Web browsers are set to accept cookies by default. If you prefer, you can usually
              choose to set your browser to remove cookies and to reject cookies. If you choose to
              remove cookies or reject cookies, this could affect certain features or services of
              our Services. You may also{" "}
              <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">
                opt out of interest-based advertising by advertisers
              </a>{" "}
              on our Services.
            </Typography>
            {populatePara(
              "If you have questions or comments about your privacy rights, you may email us at contactus@newsone.ai .",
            )}
            {populateTermHeading(
              "11. CONTROLS FOR DO-NOT-TRACK FEATURES",
              "CONTROLS-FOR-DO-NOT-TRACK-FEATURES",
            )}
            {populatePara(
              "Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ( \"DNT\" ) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized . As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.",
            )}
            {populatePara(
              "California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.",
            )}
            {populateTermHeading(
              "12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
              "DO-UNITED-STATES-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> If you are a resident of California,
              Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New
              Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia , you may have the
              right to request access to and receive details about the personal information we
              maintain about you and how we have processed it, correct inaccuracies, get a copy of,
              or delete your personal information. You may also have the right to withdraw your
              consent to our processing of your personal information. These rights may be limited in
              some circumstances by applicable law. More information is provided below.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              Categories of Personal Information We Collect
            </Typography>
            {populatePara(
              "We have collected the following categories of personal information in the past twelve (12) months:",
            )}
            <TableContainer>
              <Table
                sx={{
                  mb: 2,
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "td, th": {
                        border: `1px solid ${theme.palette.divider}`,
                        fontWeight: "bold",
                        sm: {
                          padding: 16,
                          border: `1px solid ${theme.palette.divider}`,
                          fontWeight: "bold",
                        },
                        xs: {
                          padding: 2,
                          border: `1px solid ${theme.palette.divider}`,
                          fontWeight: "bold",
                        },
                      },
                    }}
                  >
                    <TableCell>Category</TableCell>
                    <TableCell>Examples</TableCell>
                    <TableCell>Collected</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    {
                      category: "A. Identifiers",
                      examples:
                        "Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name",
                      collected: "NO",
                    },
                    {
                      category:
                        "B. Personal information as defined in the California Customer Records statute",
                      examples:
                        "Name, contact information, education, employment, employment history, and financial information",
                      collected: "NO",
                    },
                    {
                      category:
                        "C. Protected classification characteristics under state or federal law",
                      examples:
                        "Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data",
                      collected: "NO",
                    },
                    {
                      category: "D. Commercial information",
                      examples:
                        "Transaction information, purchase history, financial details, and payment information",
                      collected: "NO",
                    },
                    {
                      category: "E. Biometric information",
                      examples: "Fingerprints and voiceprints",
                      collected: "NO",
                    },
                    {
                      category: "F. Internet or other similar network activity",
                      examples:
                        "Browsing history, search history, online behavior , interest data, and interactions with our and other websites, applications, systems, and advertisements",
                      collected: "NO",
                    },
                    {
                      category: "G. Geolocation data",
                      examples: "Device location",
                      collected: "YES",
                    },
                    {
                      category: "H. Audio, electronic, sensory, or similar information",
                      examples:
                        "Images and audio, video or call recordings created in connection with our business activities",
                      collected: "NO",
                    },
                    {
                      category: "I. Professional or employment-related information",
                      examples:
                        "Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us",
                      collected: "NO",
                    },
                    {
                      category: "J. Education Information",
                      examples: "Student records and directory information",
                      collected: "NO",
                    },
                    {
                      category: "K. Inferences drawn from collected personal information",
                      examples:
                        "Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics",
                      collected: "NO",
                    },
                    {
                      category: "L . Sensitive personal Information",
                      examples: "",
                      collected: "NO",
                    },
                  ].map((row) => (
                    <TableRow
                      key={row.category}
                      sx={{
                        "td, th": {
                          border: `1px solid ${theme.palette.divider}`,
                          xs: {
                            border: `1px solid ${theme.palette.divider}`,
                          },
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.category}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.examples}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.collected}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {populatePara(
              "We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:",
            )}
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "Receiving help through our customer support channels;",
                  lists: [],
                  links: [],
                },
                {
                  text: "Participation in customer surveys or contests; and",
                  lists: [],
                  links: [],
                },
                {
                  text: "Facilitation in the delivery of our Services and to respond to your inquiries.",
                  lists: [],
                  links: [],
                },
              ],
            })}
            {populatePara(
              "We will use and retain the collected personal information as needed to provide the Services or for:",
            )}
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "Category G - As per google analytics policies",
                  lists: [],
                  links: [],
                },
              ],
            })}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              Sources of Personal Information
            </Typography>
            <Typography variant="body1" mb={2}>
              Learn more about the sources of personal information we collect in "
              <a href="#WHAT-INFORMATION-DO-WE-COLLECT?">WHAT INFORMATION DO WE COLLECT?</a>"
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              How We Use and Share Personal Information
            </Typography>
            <Typography variant="body1" mb={2}>
              Learn about how we use your personal information in the section, "
              <a href="#HOW-DO-WE-PROCESS-YOUR-INFORMATION?">HOW DO WE PROCESS YOUR INFORMATION?</a>
              "
            </Typography>
            <Typography variant="subtitle2" color="secondary" fontWeight="bold" mb={1.5}>
              Will your information be shared with anyone else?
            </Typography>
            <Typography variant="body1" mb={2}>
              We may disclose your personal information with our service providers pursuant to a
              written contract between us and each service provider. Learn more about how we
              disclose personal information to in the section, "
              <a href="#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?">
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
              "
            </Typography>
            {populatePara(
              "We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be \"selling\" of your personal information.",
            )}
            {populatePara(
              "We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.",
            )}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              Your Rights
            </Typography>

            {populatePara(
              "You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:",
            )}
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "Right to know whether or not we are processing your personal data",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to access your personal data",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to correct inaccuracies in your personal data",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to request the deletion of your personal data",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to obtain a copy of the personal data you previously shared with us",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to non-discrimination for exercising your rights",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law) , the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ( \"profiling\" )",
                  lists: [],
                  links: [],
                },
              ],
            })}
            {populatePara(
              "Depending upon the state where you live, you may also have the following rights:",
            )}
            {poppulateNestedList({
              type: CONTENT_TYPES.NESTED_UNORDERED_LIST,
              lists: [
                {
                  text: "Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon’s privacy law)",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)",
                  lists: [],
                  links: [],
                },
                {
                  text: "Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)",
                  lists: [],
                  links: [],
                },
              ],
            })}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              How to Exercise Your Rights
            </Typography>
            {populatePara(
              "To exercise these rights, you can contact us by emailing us at contactus@newsone.ai , or by referring to the contact details at the bottom of this document.",
            )}
            {populatePara(
              "Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.",
            )}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              Request Verification
            </Typography>
            {populatePara(
              "Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.",
            )}
            {populatePara(
              "If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.",
            )}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              Appeals
            </Typography>
            {populatePara(
              "Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at contactus@newsone.ai . We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.",
            )}
            <Typography variant="subtitle1" fontWeight="bold" mb={1.5}>
              California "Shine The Light" Law
            </Typography>
            <Typography variant="body1" mb={2}>
              California Civil Code Section 1798.83, also known as the "Shine The Light" law,
              permits our users who are California residents to request and obtain from us, once a
              year and free of charge, information about categories of personal information (if any)
              we disclosed to third parties for direct marketing purposes and the names and
              addresses of all third parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California resident and would like
              to make such a request, please submit your request in writing to us by using the
              contact details provided in the section "
              <a href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              "
            </Typography>
            {populateTermHeading(
              "13. DO WE MAKE UPDATES TO THIS NOTICE?",
              "DO-WE-MAKE-UPDATES-TO-THIS-NOTICE?",
            )}
            <Typography variant="body1" mb={2} fontStyle="italic">
              <span className="bold-text">In Short:</span> Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </Typography>
            {populatePara(
              "We may update this privacy notice from time to time. The updated version will be indicated by an updated \"Revised\" date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.",
            )}
            {populateTermHeading(
              "14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
              "HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?",
            )}
            {populatePara(
              "If you have questions or comments about this notice, you may email us at contactus@newsone.ai or contact us by post at:",
            )}
            <Typography fontWeight="bold">Custofar Technologies Pvt. Ltd.</Typography>
            <Typography fontWeight="bold">
              Plot No. 89, Girija Nagar, Bhandewadi, Railway Station Road,
            </Typography>
            <Typography fontWeight="bold">Nagpur, Maharashtra, 440035</Typography>
            <Typography fontWeight="bold" mb={2}>
              India.
            </Typography>

            {populateTermHeading(
              "15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
              "HOW-CAN-YOU-REVIEW,-UPDATE,-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU?",
            )}
            <Typography variant="body1" mb={2}>
              Based on the applicable laws of your country or state of residence in the US, you may
              have the right to request access to the personal information we collect from you,
              details about how we have processed it, correct inaccuracies, or delete your personal
              information. You may also have the right to withdraw your consent to our processing of
              your personal information. These rights may be limited in some circumstances by
              applicable law. To request to review, update, or delete your personal information,
              please send a data subject access request to contactus@newsone.ai .
            </Typography>
          </Grid>
          <Grid md={3} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </>
  )
}
