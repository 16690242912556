import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { type HeaderTags } from "./types"

const SeoUpdater = (props: {
  headerTags: HeaderTags
  canonicalUrl?: string
}): React.ReactElement => {
  const {
    headerTags: { title, commaSeparatedKeywords, titleTemplate, description, imageUrl, url },
    canonicalUrl,
  } = props
  const [allTags, setAllTags] = useState<React.ReactElement[]>([])

  useEffect(() => {
    const titles = getTitles()
    const keyWords = getKeyWords()

    const tags = [...titles, ...keyWords]
    setAllTags(tags)
  }, [title, commaSeparatedKeywords])

  const getTitles = (): React.ReactElement[] => {
    const fullUrl = `${process.env?.REACT_APP_BASE_URL ?? window.location.origin}${url}`
    const titles = []
    if (title?.length > 0) {
      // twitter tags
      titles.push(<meta key={"twitter_card"} name="twitter:card" content="summary_large_image" />)
      titles.push(<meta key="twitter_site" name="twitter:site" content="@newsoneai" />)
      titles.push(<meta key="twitter_creator" name="twitter:creator" content="@newsoneai" />)
      titles.push(<meta key={"twitter_title"} property="twitter:title" content={title} />)
      titles.push(
        <meta key={"twitter_description"} property="twitter:description" content={description} />,
      )
      titles.push(<meta key="twitter_image" name="twitter:image" content={imageUrl} />)
      // twitter tags end
      titles.push(<title key={"head_title"}>{title}</title>)
      titles.push(<meta key="description" name="description" content={description} />)
      titles.push(<meta key={"og_title"} property="og:title" content={title} />)
      titles.push(<meta key={"og_url"} property="og:url" content={fullUrl} />)
      titles.push(<meta key={"og_description"} property="og:description" content={description} />)
      titles.push(<meta key={"og_image"} property="og:image" content={imageUrl} />)
      if (canonicalUrl) {
        titles.push(<link rel="canonical" href={fullUrl} />)
      }
    }
    return titles
  }

  const getKeyWords = (): React.ReactElement[] => {
    const collection = []
    if (commaSeparatedKeywords?.length > 0) {
      collection.push(
        <meta key={"head_keywords"} property="keywords" content={commaSeparatedKeywords} />,
      )
    }

    return collection
  }

  return <>{allTags.length > 0 && <Helmet titleTemplate={titleTemplate}>{allTags}</Helmet>}</>
}

export default SeoUpdater
