import React, { useMemo, useState } from "react"
import { type NavItem } from "../../types"

interface AppContextType {
  addBreadcrumb: (path: NavItem) => void
  clearBreadcrumb: () => void
  breadCrumb: NavItem[]
}
const defaultAppContext: AppContextType = {
  addBreadcrumb: () => {},
  clearBreadcrumb: () => {},
  breadCrumb: [],
}
export const AppContext = React.createContext(defaultAppContext)

export const AppContextWrapper = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const [breadCrumb, setBreadCrumb] = useState<NavItem[]>([])
  const appContext: AppContextType = useMemo(
    () => ({
      addBreadcrumb: (path: NavItem) => {
        setBreadCrumb((prevBreadcrumb) => [...prevBreadcrumb, path])
      },
      clearBreadcrumb: () => {
        setBreadCrumb([])
      },
      breadCrumb,
    }),
    [breadCrumb],
  )

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
}
