import ReactGA from "react-ga4"

export const sendClickEvent = ({
  category,
  label,
  action,
}: {
  category: string
  label: string
  action: string
}): void => {
  // Send a custom event
  ReactGA.event({
    category,
    action, // action goes as event name
    label, // optional
    value: 1, // optional, must be a number
    nonInteraction: false, // optional, true/false
  })
}
