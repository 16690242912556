import {
  Close as CloseIcon,
  Facebook,
  Reddit,
  Share as ShareIcon,
  Twitter,
} from "@mui/icons-material"
import { ButtonGroup, IconButton, Snackbar, type SxProps, Tooltip, Box } from "@mui/material"
import { SHARE } from "../../constants"
import React, { Fragment, useState } from "react"
import { sendClickEvent } from "../../analytics/ga"

const ShareButtons = ({
  sx,
  url,
  shareTitle,
  shareText,
}: {
  sx: SxProps
  url?: string
  shareTitle: string
  shareText: string
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  const openSnackbar = (): void => {
    setIsOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === "clickaway") {
      return
    }

    setIsOpen(false)
  }

  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  )

  const copyURLAndNotify = (socialLink: string): void => {
    void navigator.clipboard.writeText(socialLink)
    openSnackbar()
  }

  const openLink = (socialLink: string): void => {
    window.open(socialLink, "_blank")
  }

  const webShare = (socialLink: string): void => {
    const shareData = {
      title: shareTitle,
      text: shareText,
      url: socialLink,
    }
    sendClickEvent({
      action: "webShareStart",
      category: "webShare",
      label: `Web share ${socialLink}`,
    })
    try {
      navigator
        .share(shareData)
        .then(() => {
          sendClickEvent({
            action: "webShareSuccess",
            category: "webShare",
            label: `Web share ${socialLink}`,
          })
        })
        .catch(() => {
          sendClickEvent({
            action: "webShareError",
            category: "webShare",
            label: `Web share ${socialLink}`,
          })
        })
    } catch {
      copyURLAndNotify(socialLink)
    }
  }

  const handleShare = (medium: string): void => {
    const ahref = url ?? window.location.href
    const encodedAhref = encodeURIComponent(ahref)
    let link

    switch (medium) {
      case SHARE.FACEBOOK:
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`
        openLink(link)
        break

      case SHARE.TWITTER:
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}`
        openLink(link)
        break

      case SHARE.REDDIT:
        link = `https://www.reddit.com/submit?url=${encodedAhref}`
        openLink(link)
        break

      case SHARE.LINK:
        if (navigator.canShare({ text: ahref })) {
          webShare(ahref)
        } else {
          copyURLAndNotify(ahref)
        }
        break

      default:
        break
    }
  }

  return (
    <>
      <Box sx={{ ...sx }}>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Tooltip title="Facebook Share">
            <IconButton
              onClick={(): void => {
                handleShare(SHARE.FACEBOOK)
              }}
            >
              <Facebook color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Twitter Share">
            <IconButton
              onClick={() => {
                handleShare(SHARE.TWITTER)
              }}
            >
              <Twitter color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reddit Share">
            <IconButton
              onClick={() => {
                handleShare(SHARE.REDDIT)
              }}
            >
              <Reddit color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share Link">
            <IconButton
              onClick={(e): void => {
                handleShare(SHARE.LINK)
              }}
            >
              <ShareIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Link Copied"
        action={action}
      />
    </>
  )
}

export default ShareButtons
