import React from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material"
import ShareButtons from "../../Components/ShareButtons"
import { Link } from "react-router-dom"
import Grid from "@mui/material/Unstable_Grid2"
import tributToMatthewPerry from "../../assets/articles/matthew-perry.json"
import annotationIndustry from "../../assets/articles/annotation-industry.json"
import indiaVsAustraliaFinal2023 from "../../assets/articles/india-vs-austalia-final-2023.json"
import whatIsCaa from "../../assets/articles/what-is-caa.json"
import reportingOnPsychedelics from "../../assets/articles/reporting-on-psychedelics-research.json"
import extremeHeatHealthAffects from "../../assets/articles/extreame-heat-health-research-roundup.json"

import SeoUpdater from "../../Components/SeoUpdater"

export default function Home(): React.ReactElement {
  return (
    <>
      <SeoUpdater
        headerTags={{
          title: "NewsOne.ai",
          commaSeparatedKeywords: "News, NewsOne, News One India, News One AI",
          titleTemplate: "%s",
          description: "NewsOne.ai - Connecting the dots.",
          imageUrl: "https://www.newsone.ai/images/NewsOneLightLogo.png",
          url: "",
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid md={8} sm={12} xs={12}>
            <Card>
              <Link to={whatIsCaa.url} style={{ textDecoration: "none", color: "inherit" }}>
                <CardMedia
                  sx={{ height: 350 }}
                  image={whatIsCaa.image_url}
                  title={whatIsCaa.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {whatIsCaa.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {whatIsCaa.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={whatIsCaa.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${whatIsCaa.url}`}
                        shareText={whatIsCaa.short_text}
                        shareTitle={whatIsCaa.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}></Grid>

          <Grid md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h2">
              Trending
            </Typography>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            <Card>
              <Link
                to={reportingOnPsychedelics.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={reportingOnPsychedelics.image_url}
                  title={reportingOnPsychedelics.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {reportingOnPsychedelics.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {reportingOnPsychedelics.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={reportingOnPsychedelics.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${reportingOnPsychedelics.url}`}
                        shareText={reportingOnPsychedelics.short_text}
                        shareTitle={reportingOnPsychedelics.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            <Card>
              <Link
                to={extremeHeatHealthAffects.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={extremeHeatHealthAffects.image_url}
                  title={extremeHeatHealthAffects.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {extremeHeatHealthAffects.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {extremeHeatHealthAffects.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={extremeHeatHealthAffects.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${extremeHeatHealthAffects.url}`}
                        shareText={extremeHeatHealthAffects.short_text}
                        shareTitle={extremeHeatHealthAffects.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            <Card>
              <Link
                to={tributToMatthewPerry.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={tributToMatthewPerry.image_url}
                  title="Matthew Perry"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {tributToMatthewPerry.title}
                  </Typography>
                  <Typography variant="body2" component="h4" color="text.secondary">
                    {tributToMatthewPerry.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={tributToMatthewPerry.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${tributToMatthewPerry.url}`}
                        shareText={tributToMatthewPerry.short_text}
                        shareTitle={tributToMatthewPerry.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            <Card>
              <Link
                to={annotationIndustry.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={annotationIndustry.image_url}
                  title={annotationIndustry.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {annotationIndustry.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {annotationIndustry.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={annotationIndustry.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${annotationIndustry.url}`}
                        shareText={annotationIndustry.short_text}
                        shareTitle={annotationIndustry.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={4} sm={12} xs={12}>
            <Card>
              <Link
                to={indiaVsAustraliaFinal2023.url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  sx={{ height: 350 }}
                  image={indiaVsAustraliaFinal2023.image_url}
                  title={indiaVsAustraliaFinal2023.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h3">
                    {indiaVsAustraliaFinal2023.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" component="h4">
                    {indiaVsAustraliaFinal2023.short_text}
                  </Typography>
                </CardContent>
              </Link>
              <CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <Button color="secondary" variant="contained">
                        <Link
                          to={indiaVsAustraliaFinal2023.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Read More
                        </Link>
                      </Button>
                    </Grid>
                    <Grid>
                      <ShareButtons
                        sx={{ p: 0, paddingTop: 0 }}
                        url={`${window.location.origin}${indiaVsAustraliaFinal2023.url}`}
                        shareText={indiaVsAustraliaFinal2023.short_text}
                        shareTitle={indiaVsAustraliaFinal2023.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
